<template>
  <div class="w-full flex flex-col justify-center items-center gap-10">
    <Wrapper class="max-w-2xl">
      <h2 class="text-left text-3xl text-blue-dark py-2">
        <b>Formulario para presentar una inconformidad</b>
      </h2>
      <p class="mt-6 mb-4 text-left text-xs text-gray-400">
        Complete los campos solicitados y la información detallada.
      </p>

      <div v-if="customer">
        <h2 class="my-5 text-2xl text-left text-blue">
          <b>Datos de contacto</b>
        </h2>
        <div class="grid grid-cols-2 gap-4 w-full">
          <BaseRadio
            ref="personType"
            name="personType"
            id="personType"
            label="Tipo de persona"
            :radioOptions="formOptions.optionsPersonType"
            v-model="customer.person_type"
            :disabled="disabled"
          />
        </div>

        <!-- <CustomerNatural
          v-if="customer && customer.person_type == 1"
          :customer="customer"
        />
        <CustomerLegal
          v-if="customer && customer.person_type == 2"
          :customer="customer"
        /> -->

        <ComplaintForm :customer="customer" />
        <a-modal
          v-model="modalInfoVisible"
          centered
          :footer="null"
          :closable="true"
          @ok="() => (modalInfoVisible = false)"
        >
          <h1 class="mb-6 text-xl text-left text-blue"><b>Recuerde...</b></h1>
          <a-timeline>
            <a-timeline-item>
              En caso de que su inconformidad esté relacionada con un reporte de
              operadores o centrales de información, es necesario que haya
              presentado una queja ante la entidad vigilada o su Defensor del
              Consumidor Financiero.
            </a-timeline-item>

            <a-timeline-item
              >La información del Consumidor Financiero es indispensable para
              erradicar la inconformidad..</a-timeline-item
            >
            <hr class="my-4" />
          </a-timeline>
        </a-modal>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ComplaintForm from "@/components/Customer/Complaint/ComplaintForm";
import CustomerNatural from "@/components/Dashboard/Customers/CustomerNatural";
import CustomerLegal from "@/components/Dashboard/Customers/CustomerLegal";

export default {
  components: {
    ComplaintForm,
    CustomerLegal,
    CustomerNatural,
  },
  data() {
    return {
      customer: {},
      loading: false,
      disabled: false,
      modalInfoVisible: false,
      personalDataAuthorized: false,
      checkSensitiveData: 2,
      checkPersonalData: 2,
      feedback: null,
      optionsYN: [
        { id: 1, name: "Sí" },
        { id: 2, name: "No" },
      ],
    };
  },
  async created() {
    const { identification_type, identification_number } = this.$route.query;

    this.loading = true;
    let { data, error } = await this.$api.getQueryPerson(
      `?identification_number=${identification_number}&identification_type=${identification_type}`
    );
    this.loading = false;

    if (error) {
      this.showToast(
        "error",
        "No se encontró un usuario para esa identificación."
      );
      return;
    }
    this.disabled = true;

    if (data) {
      if (data.results.length === 0) {
        this.showToast(
          "error",
          "No se encontró un usuario para esa identificación."
        );
        return;
      }
      // Extra: Validation for only CF users
      if (data.results.length > 1) {
        data.results = data.results.filter((item) => item.position === null);
      }
      const user = data.results[0];
      const identification_type_name =
        this.$store.state.form.optionsIdentificationType.find(
          (item) => item.id == user.identification_type
        )?.name;

      this.customer = {
        identification_type: user.identification_type,
        identification_number: user.identification_number,
        birth_date: user.birth_date,
        person_type: user.person_type,
        first_name: user.user_id.first_name,
        last_name: user.user_id.last_name,
        phone: user.user_id.phone,
        email: user.user_id.email,
        user_id: user.user_id?.id,
        identification_type_name,
        business_name: user.user_id?.business_name,
        departamento_cod: user.user_id?.departamento_cod,
        municipio_cod: user.user_id?.municipio_cod,
        address: user.user_id?.address,
      };
    }
  },
  computed: {
    ...mapState({
      formOptions: (state) => {
        return state.form;
      },
    }),
  },
  methods: {
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
    authorizePersonalDataProcessing() {
      if (this.checkPersonalData != 1) {
        // this.showToast("error","Debes aceptar el tratamiento de datos personales antes de continuar")
        this.feedback =
          "Debes aceptar el tratamiento de datos personales antes de continuar";
      } else {
        this.personalDataAuthorized = true;
        this.modalInfoVisible = false;
      }
    },
    onCancelModal() {
      this.$router.go(-2);
    },
  },
  watch: {
    // modalInfoVisible(newValue) {
    //   if (!newValue && !this.personalDataAuthorized) {
    //     this.showToast(
    //       "error",
    //       "Debes aceptar el tratamiento de datos personales antes de continuar"
    //     );
    //     this.modalInfoVisible = true;
    //   }
    // },
    checkPersonalData(newValue) {
      if (newValue != 1) {
        this.feedback =
          "Debes aceptar el tratamiento de datos personales antes de continuar";
      } else {
        this.feedback = "";
      }
    },
  },
};
</script>